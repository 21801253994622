<template>
  <div class="footer">
    <ul class="social__links social__links--center">
      <li class="mx-10" title="Github">
        <a href="https://www.github.com/maxaakre" class="color-gray"
          ><i class="fab fa-github"></i
        ></a>
      </li>
      <li class="mx-10" title="Facebook">
        <a
          href="https://www.facebook.com/maxaakre"
          class="color-gray"
          ><i class="fab fa-facebook-f"></i
        ></a>
      </li>
      <li class="mx-10" title="Linkedin">
        <a
          href="https://www.linkedin.com/in/maxaakre/"
          class="color-gray"
          ><i class="fab fa-linkedin-in"></i
        ></a>
      </li>
    </ul>
    <h1 class="footer__title footer__title--center ">
      Contact me <br /><a class="footer__sub-title__a" href="mailto:maxaakre@gmail.com"><span class="footer__sub-title"
        >maxaakre@gmail.com</span>
      </a>
    </h1>
    <p class="footer__title--center footer__sub-title"> © Copyright 2021 Max Aakre. All rights reserved </p>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>
